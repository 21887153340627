<script>
  import { Line, mixins } from 'vue-chartjs'
  const { reactiveProp } = mixins

  export default {
    extends: Line,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object,
        default: {}
      },  
      maxValue: {
        type: Number,
        default: 100,
      }
    },
    data() {
      return {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {        
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                beginAtZero: true,
                max: 100
              }               
            }],                    
            yAxes: [{
              display: true,
              ticks: {
                beginAtZero: true,
                max: 100
              }
            }]
          }
        }
      }
    },
    mounted () {                        
      this.render()
    },
    watch: {
      chartData: function() {        
        this.$data._chart.update()        
      },
      maxValue: function() {                
        this.$data._chart.config.options.scales.yAxes[0].ticks.max = this.maxValue 
        this.$data._chart.update()        
      }
    }, 
    methods: {
      render() {        
        this.options.scales.yAxes[0].ticks.max = this.maxValue 
        this.renderChart(this.chartData, this.options) 
      }
    }
  }
</script>
