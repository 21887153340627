<template>
  <b-row>
    <b-col sm="12">
      <b-card class="mb-1">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Google Adwords - Reportes</h4>
            <div class="small text-muted">Métricas en tiempo real de las campañas de anuncios</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="google-adwords-reports-card-filter mb-1">        
        <b-row>
          <b-col md="8" class="mt-1">          
            <span v-if="!arr.labelTemporal">
              <strong>Cuenta Monitoreada:</strong> ({{this.arr.clientCustomerID}}) - {{this.arr.label}}            
            </span>
            <span v-else class="google-adwords-reports-card-account-temporary">
              <strong>Cuenta Temporal:</strong> ({{this.arr.clientCustomerIDTemporal}}) - {{this.arr.labelTemporal}}
            </span>
            <b-icon icon="blank" />
            <b-icon icon="blank" />
            <b-link size="sm" 
                    class="google-adwords-reports-card-change-account" 
                    @click="openChangeAccount()">
              <b-icon icon="arrow-left-right" />
              Cambiar Cuenta
            </b-link>            
          </b-col>
          <b-col md="4">
            <b-row class="justify-content-end">
              <b-col md="4" class="pr-1">
                <b-form-input type="date" size="sm" v-model="filter.dateStart" placeholder="Fecha Desde"></b-form-input>                
              </b-col>
              <b-col md="4" class="pr-1">
                <b-form-input type="date" size="sm" v-model="filter.dateEnd" placeholder="Fecha Hasta"></b-form-input>                
              </b-col> 
              <b-col md="2" class="pl-0">
                <b-button @click="getReport()" size="sm" class="w-100" variant="dark">
                  Filtrar
                </b-button>       
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <b-card-group deck>
        <b-card class="google-adwords-reports-widget-card-summary">          
          <b-card-text>            
            <strong>{{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(this.arr.metrics.cost)}}</strong>                        
            <p>Gasto</p>
          </b-card-text>
        </b-card>
        <b-card class="google-adwords-reports-widget-card-summary">          
          <b-card-text>
            <strong>{{Intl.NumberFormat('es-AR').format(this.arr.metrics.clicks)}}</strong>
            <p>Clicks</p>
          </b-card-text>
        </b-card>        
        <b-card class="google-adwords-reports-widget-card-summary">          
          <b-card-text>
            <strong>{{Intl.NumberFormat('es-AR').format(this.arr.metrics.impressions)}}</strong>
            <p>Impresiones</p>
          </b-card-text>
        </b-card>                
        <b-card class="google-adwords-reports-widget-card-summary">          
          <b-card-text>            
            <strong>{{Intl.NumberFormat('es-AR').format(this.arr.metrics.conversions)}}</strong>            
            <p>Conversiones</p>
          </b-card-text>
        </b-card>   
        <b-card class="google-adwords-reports-widget-card-summary">          
          <b-card-text>
            <strong>{{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(this.arr.metrics.cpc)}}</strong>                        
            <p>CPC medio</p>
          </b-card-text>
        </b-card>   
        <b-card class="google-adwords-reports-widget-card-summary">          
          <b-card-text>
            <strong>{{this.arr.metrics.ctr.toFixed(2)}} %</strong>
            <p>CTR</p>
          </b-card-text>
        </b-card>                           
      </b-card-group>

      <b-row class="mt-1">
        <b-col md="6">
          <b-card border-variant="dark"
                  header-bg-variant="dark"
                  header-text-variant="white"
                  align="left"
                  class="google-adwords-reports-widget-card-chart">

            <div slot="header" class="font-weight-bold">
              <strong> Resumen desde <u>{{moment(this.filter.dateStart).format('DD MMMM')}}</u> al <u>{{moment(this.filter.dateEnd).format('DD MMMM')}}</u> </strong>                        
            </div>     
            
            <line-chart
              v-if="chartSummary.loaded"
              :chartData="chartSummary.chartdata"
              :maxValue="chartSummary.maxValue"
            />            
            <b-alert v-else show variant="info" class="google-adwords-reports-widget-card-char-alert">
              <h4 class="alert-heading">Sin Registros</h4>
              <p>
                No se encontraron datos desde el día {{moment(this.filter.dateStart).format('DD/MM/YYYY')}} hasta {{moment(this.filter.dateEnd).format('DD/MM/YYYY')}}
              </p>
            </b-alert>            

          </b-card>
        </b-col>

        <b-col md="6">
          <b-card border-variant="dark"
                  header-bg-variant="dark"
                  header-text-variant="white"
                  align="left"
                  class="google-adwords-reports-widget-card-chart">

            <div slot="header" class="font-weight-bold">
              <strong> Impresiones desde <u>{{moment(this.filter.dateStart).format('DD MMMM')}}</u> al <u>{{moment(this.filter.dateEnd).format('DD MMMM')}}</u> </strong>                        
            </div>     

            <line-chart
              v-if="chartImpressions.loaded"
              :chartData="chartImpressions.chartdata"
              :maxValue="chartImpressions.maxValue"
            />            
            <b-alert v-else show variant="info" class="google-adwords-reports-widget-card-char-alert">
              <h4 class="alert-heading">Sin Registros</h4>
              <p>
                No se encontraron datos desde el día {{moment(this.filter.dateStart).format('DD/MM/YYYY')}} hasta {{moment(this.filter.dateEnd).format('DD/MM/YYYY')}}
              </p>
            </b-alert>

          </b-card>
        </b-col>
      </b-row>
        
      <b-card>
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              size="sm"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">                
            <b-form-group label="Client Customer ID">
              <b-form-input type="text"
                            size="sm"
                            v-model="filter.clientCustomerID"                            
                            placeholder="xxx-xxx-xxxx"
                            autocomplete="off">
              </b-form-input>              
            </b-form-group>      
          </b-col>       
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" size="sm" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" size="sm" @click="changeAccount()">Consultar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'    
  import moment from 'moment'
  import LineChart from './chart/line'    

  export default {  
    components: { 
      LineChart,    
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.GOOGLE_ADWORDS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'reports',
          elements: {}
        }, 
        arr: {
          data: [],
          label: '',
          clientCustomerID: '',
          labelTemporal: '',
          clientCustomerIDTemporal: '',
          metrics: {
            cost: 0,
            clicks: 0,
            impressions: 0,
            conversions: 0,
            ctr: 0,
            cpc: 0,
          }
        },
        filter: {
          id: 1,
          dateStart: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          dateEnd:  moment().format('YYYY-MM-DD'),
          clientCustomerID: '',
        },
        chartSummary: {
          loaded: false,
          realTime: {
            interval: null,
            refresh: 5000,
          },
          chartdata: {},
          maxValue: 0,
        },           
        chartImpressions: {
          loaded: false,
          realTime: {
            interval: null,
            refresh: 5000,
          },
          chartdata: {},
          maxValue: 0,
        },  
        modal: {
          form: {
            active: false,
            title: ''
          },
        },                                       
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.showGoogleAdwords()
      this.getReport()
    },
    methods: {      
      showGoogleAdwords() {        
        let loader = this.$loading.show();        
        var result = serviceAPI.mostrarGoogleAdwords(this.filter.id)

        result.then((response) => {
          loader.hide()
          var data = response.data
          this.arr.label = data.name                     
          this.arr.clientCustomerID = data.clientCustomerID
        })
        .catch(error => {          
          loader.hide()            
          this.$awn.alert(Error.showError(error))
        });        
      },             
      getReport() {        
        let loader = this.$loading.show();        
        var result = serviceAPI.obtenerReportGeneralSummaryGoogleAdwords(this.filter)

        result.then((response) => {
          loader.hide()
          var data = response.data
          this.arr.data = data.data
          
          this.arr.labelTemporal= data.label  
          this.arr.clientCustomerIDTemporal= this.filter.clientCustomerID

          this.calculate()       
          this.loadChart() 
        })
        .catch(error => {             
          loader.hide()            
          this.$awn.alert(Error.showError(error))
        });        
      },       
      calculate () {        
        this.arr.metrics.cost = 0
        this.arr.metrics.clicks = 0
        this.arr.metrics.impressions = 0
        this.arr.metrics.conversions = 0
        this.arr.metrics.ctr = 0
        this.arr.metrics.cpc = 0               

        if(this.arr.data) {                    
          for (const prop in this.arr.data) {
            var element = this.arr.data[prop]
            
            this.arr.metrics.cost = this.arr.metrics.cost + parseFloat(element.cost)
            this.arr.metrics.clicks = this.arr.metrics.clicks + parseInt(element.clicks)
            this.arr.metrics.impressions = this.arr.metrics.impressions + parseInt(element.impressions)
            this.arr.metrics.conversions = this.arr.metrics.conversions + parseFloat(element.conversions)
          }                    
          
          if(this.arr.metrics.clicks) {
            this.arr.metrics.cpc = this.arr.metrics.cost / this.arr.metrics.clicks
          }
          
          if(this.arr.metrics.impressions) {
            this.arr.metrics.ctr = (this.arr.metrics.clicks / this.arr.metrics.impressions) * 100
          }
        }
      },
      loadChart() {        
        var data = this.arr.data              

        if(data.length != 0) {                  
          this.initChartData()
          var maxValueCost = 0
          var maxValueImpressions = 0

          for (const prop in data) {
            var element = this.arr.data[prop]

            var day = moment(element.date).format('DD MMMM')
            this.chartSummary.chartdata.labels.push(day)            
            this.chartImpressions.chartdata.labels.push(day)

            this.chartSummary.chartdata.datasets[0].data.push(element.cost.toFixed(2))            
            this.chartSummary.chartdata.datasets[1].data.push(element.conversions)     
            this.chartSummary.chartdata.datasets[2].data.push(element.clicks)                          

            this.chartImpressions.chartdata.datasets[0].data.push(element.impressions)            
            this.chartImpressions.chartdata.datasets[1].data.push(element.conversions)                 

            if(maxValueCost<element.cost) {
              maxValueCost = element.cost
            }

            if(maxValueImpressions<element.impressions) {
              maxValueImpressions = element.impressions
            }            
          }        

          this.chartSummary.loaded = true            
          this.chartImpressions.loaded = true

          this.chartSummary.maxValue = parseInt(maxValueCost * 1.1)
          this.chartImpressions.maxValue = parseInt(maxValueImpressions * 1.1)

        } else {          
            this.chartSummary.loaded = false            
            this.chartImpressions.loaded = false                  
        } 
      },       
      initChartData() {
        this.chartSummary.chartdata = {
          labels: [],
          datasets: [{
            type: 'line',
            label: 'Gasto',
            backgroundColor: '#0000ff70',
            order:2,
            pointRadius: 2,            
            data: [],
          },{
				    type: 'bar',
				    label: 'Conversiones',
            backgroundColor: '#00800090',
            order:3,
            pointRadius: 2,
				    data: [],
				    borderColor: 'white',
				    borderWidth: 1
			    },{
				    type: 'line',
				    label: 'Clicks',
            backgroundColor: '#ff000070',
            order:1,            
            pointRadius: 2,            
				    data: [],				    
			    }]
        }

        this.chartImpressions.chartdata = {
          labels: [],
          datasets: [{
            type: 'line',
            label: 'Impresiones',
            backgroundColor: '#ffa50070',
            order:1,
            pointRadius: 2,            
            data: [],
          },{
				    type: 'bar',
				    label: 'Conversiones',
            backgroundColor: '#00800090',
            order:2,
            pointRadius: 2,
				    data: [],
				    borderColor: 'white',
				    borderWidth: 1
			    }]
        }        
      },  
      openChangeAccount() {
        this.modal.form.title = "Cambiar Cuenta"
        this.modal.form.active = true
      },
      changeAccount() {
        this.getReport()
        this.modal.form.active = false
      }
    }    
  }
</script>
<style>
  .google-adwords-reports-widget-card-chart .card-header {      
    padding: 5px;
  }
  .google-adwords-reports-widget-card-chart .card-body {
    padding: 5px;
  }
  .google-adwords-reports-widget-card-summary {
    height: 65px;    
    margin-bottom: 5px !important;
  }
  .google-adwords-reports-widget-card-summary .card-body {
    padding: 5px;
  }
  .google-adwords-reports-widget-card-summary .card-text {
    text-align: center;
  } 
  .google-adwords-reports-widget-card-summary strong {
    font-size: 20px;
  }  
  .google-adwords-reports-widget-card-summary p {
    margin-bottom: 0px;
  }      
  .google-adwords-reports-widget-card-char-alert {
    margin-bottom: 0px;
  }
  .google-adwords-reports-card-filter .card-body {
    padding-left: 15px;        
    padding-top: 10px;        
    padding-bottom: 10px;        
  }
  .google-adwords-reports-card-change-account {
    font-size: 12px;
  }
  .google-adwords-reports-card-account-temporary {
    color:red;
    font-style: italic;
  }
</style>